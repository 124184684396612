<template>
  <b-overlay :show="showOverlay">
    <b-col cols="12" class="mb-1 bg-white p-0">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <validation-observer ref="updateGameServiceContent" tag="div">
      <b-card class="data-edit-wrapper">
        <h2>Game Service Content</h2>
        <b-form class="mt-2">
          <b-row>
            <b-col class="" md="4">
              <b-form-group
                  label="Title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="gameContent.title"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Full Description"
              >

                <ckEditorMain ref="editorContent" :defaultContent="gameContent.description"
                              @getEditorContent="setEditorContent"
                ></ckEditorMain>

              </b-form-group>

            </b-col>
            <b-col cols="3">
              <b-button
                  class="mt-2 ml-2"
                  variant="primary"
                  @click="updateGameServiceContent"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>

  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCarousel,
  BCarouselSlide,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { GetGameServiceContent, UpdateGameServiceContent } from '@/libs/Api/gameService'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'

export default {
  components: {
    DynamicBreadCrumb,
    MediaHandler,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ckEditorMain,
    BCard,
    ValidationObserver,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  name: 'edit-game-service-content',
  title: 'edit game service content',
  data() {
    return {
      //service 1
      // gold 2
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      gameContent: null,
      pageBreadcrumb:[
        {
          text: 'Manage Games',
          href:'/apps/games/manage-games',
          active:false
        },
        {
          text: `Manage Game`,
          href:`/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active:false
        },
        {
          text: `Manage Game Service Category`,
          href:`/apps/games/game-service-categories/manage-game-service-category?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}`,
          active:false
        },
        {
          text: `Manage Game Service`,
          href:`/apps/games/game-service-categories/game-service/manage-game-services?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}`,
          active:false
        },
        {
          text: `Edit Game Service Content`,
          href:`/apps/games/game-service-categories/game-service/game-service-content/edit-game-service-content?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}`,
          active:true
        },
      ],
    }
  },

  async created() {
    await Promise.all([
      this.getGameServiceContentSection()
    ])
  },
  methods: {
    async getGameServiceContentSection() {
      let _this = this
      _this.showOverlay = true
      let getGameServiceContent = new GetGameServiceContent(_this)

      getGameServiceContent.setPartial({ id: _this.$route.query.gameServiceContentId })
      await getGameServiceContent.fetch(function (content) {
        _this.gameContent = content.data
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
        _this.showOverlay = false
      })

    },
    setEditorContent(content) {
      this.gameContent.description = content
    },
    async updateGameServiceContent() {
      let _this = this
      Helper.validateForm(_this, 'updateGameServiceContent', async () => {
        _this.showOverlay = true
        let updateGameServiceContent = new UpdateGameServiceContent(_this)
        updateGameServiceContent.setParams({
          gameServiceId: _this.$route.query.gameServiceId,
          gameServiceContentSectionId: _this.$route.query.gameServiceContentId
        })
        updateGameServiceContent.setPayload(_this.gameContent)
        await updateGameServiceContent.fetch(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.$router.push(`/apps/games/game-service-categories/game-service/manage-game-services?gameId=${_this.$route.query.gameId}&gameServiceCategoryId=${_this.$route.query.gameServiceCategoryId}&gameServiceId=${_this.$route.query.gameServiceId}`)
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },

  }
}
</script>

<style scoped>

</style>
